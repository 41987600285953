import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';

import InputWrap from '../atoms/InputWrap';
import PasswordTextField from '../atoms/PasswordTextField';
import StyledButton from '../atoms/StyledButton';
import MaskedTextField from '../molecules/MaskedTextField';
import AutocompleteCityUF from './AutocompleteCityUF';
import { SegmentOptions } from '../functions/Options';
import {
  EmailValidation,
  LastNameValidation,
  LengthValidation,
  NameValidation,
  ObjectValidation,
  PasswordValidation,
  PhoneValidation,
} from '../functions/FormValidation';
import AllowedCities from '../../static/files/AllowedCities.json';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '25px',

    '& .labels': {
      color: `${theme.palette.midGrey.main} !important`,
    },
    '& .MuiTypography-button': {
      color: theme.palette.info.main,
    },
    '& .MuiTypography-h6': {
      marginBottom: '25px',
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginBottom: '20px',
    },

    '@media screen and (max-width: 959px)': {
      padding: '20px',
    },
  },
  buttonWrap: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
}));

const OpenCompanyForm = ({
  name, isLoading, setIsLoading, data, setData, purpleFriday,
}) => {
  const classes = useStyles();
  const [error, setError] = useState({});

  const validateFields = () => (
    ObjectValidation(data, 8)
    && LengthValidation(data)
    && NameValidation(data)
    && LastNameValidation(data)
    && PhoneValidation(data)
    && EmailValidation(data)
    && PasswordValidation(data)
    // && TermsOfUseValidation(data)
  );

  const isFormValid = () => {
    try {
      return validateFields();
    } catch (e) {
      return false;
    }
  };

  const validateForm = () => {
    try {
      if (validateFields()) {
        setError({});
      }
    } catch (e) {
      setError({ field: e.fileName, message: e.message });
      return false;
    }
    return true;
  };

  const sendDataToHubspot = async () => {
    const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID;
    const formGuid = process.env.GATSBY_HUBSPOT_ABRIR_EMPRESA_FORM_GUID;
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

    const dataHubspot = {
      fields: [
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'firstname',
          value: data.name,
        },
        {
          name: 'lastname',
          value: data.lastName,
        },
        {
          name: 'segmento_atuacao',
          value: data.segment,
        },
        {
          name: 'phone',
          value: data.phone,
        },
        {
          name: 'cidade_estado',
          value: `${data.cityName} - ${data.state}`,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    try {
      const cloudfare = await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
      if (cloudfare.data) {
        const ipInfo = cloudfare.data.split('\n')[2];
        if (ipInfo.indexOf('ip=') > -1) {
          dataHubspot.context.ipAddress = ipInfo.replace('ip=', '');
        }
      }

      await axios.post(url, dataHubspot);
    } catch (err) {
      setError({ message: 'Desculpe, ocorreu um erro. Tente novamente mais tarde!' });
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      await sendDataToHubspot();
      // o "setIsLoading" deve vir antes do submit do formulário
      // senão os campos estão como disabled e não serão submetidos
      setIsLoading(false);

      // const isAllowedCity = AllowedCities.cities.find((city) => city.code === data.city);
      // if (!isAllowedCity) {
      //   setError({
      //     message: 'No momento não estamos atendendo esta cidade. '
      //       + 'Em breve ampliaremos para diversos outros municípios.',
      //   });

      //   setIsLoading(false)
      //   return
      // }
      
      if (
        data.segment == 'Comércio' ||
        data.segment == 'Indústria'
      ){
        setError({
          message: 'Não atendemos esse segmento de atuação no momento.'
        });

        setIsLoading(false)
        return
      }

      const form = document.getElementsByName(name)[0];
      form.submit();
    }
  };

  const submitEnter = ({ key }) => {
    if (key === 'Enter') {
      handleSubmit();
    }
  };

  const changeData = (value, field) => {
    setData({ ...data, [field]: value });
    setError({});
  };

  const handleCityUFChange = (value) => {
    if (value) {
      setData({
        ...data,
        state: value.stateInitial,
        city: value.code,
        cityName: value.name,
      });
    } else {
      setData({
        ...data,
        state: null,
        city: null,
        cityName: null,
      });
    }
    setError({});
  };

  return (
    <div className={classes.root}>
      <form
        action={`${process.env.GATSBY_ABRIR_EMPRESA_BASE_URL}/signup`}
        method="post"
        name={name}
        onKeyPress={submitEnter}
        className={process.env.GATSBY_CLASS_CADASTRO_ABRIR_EMPRESA}
      >
        <Typography variant="button" className="labels">Sobre você</Typography>
        <InputWrap>
          <TextField
            label="Nome"
            name="name"
            value={data.name}
            onChange={(e) => changeData(e.target.value, 'name')}
            error={error.field === 'name'}
            disabled={isLoading}
          />
          <TextField
            label="Sobrenome"
            name="lastName"
            value={data.lastName}
            onChange={(e) => changeData(e.target.value, 'lastName')}
            error={error.field === 'lastName'}
            disabled={isLoading}
          />
          <MaskedTextField
            onChange={(e) => changeData(e.target.value, 'phone')}
            mask="+55 (99) 99999-9999"
            textFieldProps={{
              label: 'Telefone',
              name: 'phone',
              type: 'tel',
              value: data.phone,
              floating: true,
              error: error.field === 'phone',
            }}
            InputProps={{
              disabled: isLoading,
            }}
          />
        </InputWrap>

        <Typography variant="button" className="labels">Sobre sua nova empresa</Typography>
        <InputWrap>
          <TextField
            label="Qual segmento de atuação?"
            name="segmento_atuacao"
            value={data.segment}
            onChange={(e) => changeData(e.target.value, 'segment')}
            disabled={isLoading}
            select
          >
            {SegmentOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <AutocompleteCityUF
            onChange={(value) => handleCityUFChange(value)}
            value={{
              stateInitial: data.state,
              code: data.city,
              name: data.cityName,
            }}
            disabled={isLoading}
          />
        </InputWrap>

        <Typography variant="button" className="labels">Cadastro</Typography>
        <InputWrap>
          <TextField
            label="E-mail"
            name="email"
            value={data.email}
            onChange={(e) => changeData(e.target.value, 'email')}
            error={error.field === 'email'}
            disabled={isLoading}
          />
          <PasswordTextField
            label="Senha"
            name="password"
            value={data.password}
            onChange={(e) => changeData(e.target.value, 'password')}
            error={error.field === 'password'}
            disabled={isLoading}
          />
        </InputWrap>

        {/* <CheckBoxTermsOfUse
          checked={data.acceptTermsCheck}
          onChange={(value) => changeData(value, 'acceptTermsCheck')}
          color="info"
          disabled={isLoading}
        /> */}

        <FormHelperText>{error.message}</FormHelperText>

        <Box onClick={validateForm} className={classes.buttonWrap}>
          <StyledButton
            text="Criar conta"
            onClick={handleSubmit}
            disabled={!isFormValid() || isLoading}
            variant={purpleFriday ? 'purpleFriday' : 'contained'}
            color="secondary"
            width="100%"
          />
        </Box>
      </form>
    </div>
  );
};

OpenCompanyForm.propTypes = {
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  purpleFriday: PropTypes.bool.isRequired,
};

export default OpenCompanyForm;
